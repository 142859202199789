import React, {Fragment} from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Navbar className="navbar-fixed-top white-page"/>
      <header className="header legal">
        <div className="container whitepaper">
          <div className="row headline">
            <div className="col-lg-9 col-md-10 col-sm-12 col-centered">
              <div className="row">
                <div className="title">Dora
                </div>
                <h1 className="page-type">Whitepaper</h1>
                <div className="skayline"></div>
              </div>
            </div>
          </div>
        </div>
      </header>


      <section className="post-body legal">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 col-md-10 col-sm-12 col-centered">
              <div className="version">v1.0.0
              </div>
            </div>
            <div className="col-lg-9 col-md-10 col-sm-12 col-centered">
              <article className="post">
                <p>We provide a detailed overview of ou utility token $DORA, and its distribution.
                  <br/>&nbsp;</p>
                <h3>Introduction to $Dora token<br/>&nbsp;</h3>
                <p>
                  Welcome to the world of Dora, where cutting-edge AI technologies meet blockchain innovation to
                  revolutionize the way we interact with artificial intelligence. Dora introduces its native token,
                  designed to serve as the backbone of our ecosystem, facilitating seamless access to AI services,
                  incentivizing user participation, and driving growth and innovation in the AI industry.
                </p>
                <p>
                  The $Dora token ($DORA) is an ERC-20 utility token built on the BSC blockchain, designed to
                  power our AI platform and reward users for their contributions. The token serves as a medium of
                  exchange, enabling users to access AI services, pay for transactions, and participate in our staking
                  program to earn rewards.
                </p>


                <h3>Purpose and Vision<br/>&nbsp;</h3>
                <p>At Dora, our mission is to democratize access to AI technologies and empower individuals and
                  businesses to leverage the power of artificial intelligence for various applications. We envision a
                  future where AI solutions are accessible, affordable, and adaptable to diverse needs and industries.
                  With the $Dora token, we aim to create a decentralized ecosystem that fosters collaboration,
                  innovation, and value creation in the AI space.
                </p>
                <p>Leveraging our experience providing AI services for businesses in real use case scenarios, we want to
                  enable everyone to harness the power of AI. Our mission is to bring AI technology to everyone's use,
                  ensuring that individuals and businesses of all sizes can benefit from the transformative capabilities
                  of artificial intelligence.
                </p>

                <h3>Token Overview<br/>&nbsp;</h3>

                <p>The $Dora token operates on the Binance Smart Chain (BSC), chosen for its robust infrastructure,
                  high transaction throughput, low fees, and extensive network of projects and users.
                  Leveraging BSC's liquidity and market capitalization, $Dora aims to achieve widespread adoption and
                  liquidity, ensuring seamless integration into the broader blockchain ecosystem.</p>

                <p>Our ERC-20 compliant token features a simple and efficient contract design, ensuring compatibility
                  with existing wallets, exchanges, and DeFi protocols. Upon launch, ownership of the token contract
                  will be renounced, providing transparency and decentralization.
                  Integration with Pancakeswap allows for seamless liquidity provisioning and trading on decentralized
                  exchanges.</p>


                <h3>Tokenomics<br/>&nbsp;</h3>
                <p>The $Dora tokenomics model is designed to balance the interests of stakeholders while fostering
                  long-term sustainability and growth. Token distribution includes allocations for token sale, ecosystem
                  growth, team members, listings on decentralized and centralized exchanges, staking rewards, community
                  incentives, marketing initiatives, and advisor partnerships. Vesting mechanisms ensure responsible
                  token
                  distribution and alignment of incentives among team members and stakeholders.</p>
                <ul>
                  <li>Number of total tokens: 523,415,926.</li>
                  <li>Buy & Sell tax: 1% This is a tax added to replenish the staking pool - check our tokenomics page
                    where we go into detail on how we're going to use this
                    tax. We want to encourage trading, so we added a minimum tax to support high levels of trading.
                  </li>
                  <li>Ownership rennounced.</li>
                  <li>No minting, no mining and no inflation.</li>
                </ul>

                <p>We have a dedicated public document for our tokenomics, make sure to visit it for more details: <Link
                  to="/tokenomics/">Tokenomics</Link></p>
                <br/>
                <StaticImage src={"../assets/images/token-allocation-new.png"}></StaticImage>
                <br/>
                <br/>

                <h3>Utility and Use Cases<br/>&nbsp;</h3>
                <p>
                  The $Dora token serves as a utility token within our ecosystem, providing holders with access to a
                  range of AI services and platform features. Token holders may utilize $Dora tokens for AI
                  translation
                  services, customer support solutions, instruction manual assistance, sentiment analysis tools, and
                  cryptocurrency market insights. Additionally, token holders may receive discounts, rewards, and
                  exclusive benefits when utilizing Dora services.
                </p>

                <ul>
                  <li>Access to AI Services: Holders of Dora.ai tokens may use them to access AI services provided by
                    the
                    Dora.ai platform, such as content generation, natural language processing, content translation and
                    data analysis tools.
                  </li>
                  <li>Incentives and Rewards: Dora.ai tokens may be used to incentivize certain behaviors within the
                    ecosystem, such as contributing data, participating in research studies, or providing feedback on AI
                    models. Token holders may receive rewards or incentives in exchange for their contributions.
                  </li>
                  <li>Discounts and Benefits: Holders of Dora.ai tokens may receive discounts or special benefits when
                    using
                    AI services or participating in platform activities. These benefits could include reduced fees,
                    priority
                    access to services, or exclusive access to certain features.
                  </li>
                </ul>

                <p>Overall, utility tokens like Dora.ai serve to align incentives within a blockchain ecosystem,
                  incentivize participation and contribution, and provide tangible benefits to users who engage with the
                  platform.
                </p>

                <h3>Roadmap and Future Initiatives<br/>&nbsp;</h3>
                <p>Our roadmap outlines a strategic vision for the development and expansion of the Dora ecosystem.
                  Planned initiatives include the launch of AI-powered translation services, expansion into new markets
                  and industries, integration with social media platforms, and the development of innovative AI
                  applications. Community feedback and collaboration drive our continuous evolution and improvement,
                  ensuring that Dora remains at the forefront of AI innovation.
                </p>

                <p>Below is short list of items we need to accomplish before we tackle our innovative roadmap:</p>
                <ul>
                  <li>Prepare necessary documentation and legal requirements for listing on exchange platforms, ensuring
                    compliance with regulatory standards and guidelines.
                  </li>
                  <li>Establish communication channels with exchange representatives to initiate discussions and express
                    interest in listing the $Dora token.
                  </li>
                  <li>Negotiate with launchpads for a possible public sale of the $Dora token.</li>
                  <li>Create liquidity pools on DEXs.</li>
                </ul>

                {/*<h3>Future Initiatives<br/>&nbsp;</h3>*/}

                <p>
                  Here are our future initiatives, aimed to bring value to our ecosystem, utility to our token. We are
                  not restricting
                  ourselves only to these initiatives and keep an open mind for opportunities that might arise in the
                  future.
                  However, some of them are already in progress and we want to bring them to life keeping our focus on
                  them for the near future.
                </p>
                <h4>Enhancing Token Utility: Introducing Staking Functionality</h4>
                <p>we are excited to announce that we will be implementing staking functionality for the Dora.ai token.
                  Staking provides an opportunity for token holders to earn rewards by participating in network
                  validation or governance activities, thereby contributing to the security and decentralization of the
                  ecosystem. This initiative aligns with our commitment to enhancing token utility and incentivizing
                  community engagement. Stay tuned for updates as we work towards implementing staking and further
                  enhancing the value proposition of the Dora.ai token.</p>


                <h4>Generate AI Videos with SORA</h4>
                <p>Generate AI Videos with SORA: Leveraging the power of AI and the $Dora token, we will collaborate
                  with
                  SORA to enable users to generate AI videos seamlessly. Users can utilize $Dora tokens to access
                  SORA's
                  advanced video generation technology, creating engaging and personalized videos for various
                  purposes.</p>


                <h4>Public Dora Translator</h4>
                <p> With the $Dora token, users can unlock the public Dora translator,
                  allowing them to translate their website content into multiple languages simultaneously. This will be
                  available
                  as a UI tool and also as an API that helps businesses translate content in near-real time. Working as
                  a crypto
                  based SAS this will enable new usecases to businesses.</p>

                <p>Below you can see a high level abstraction diagram for the Dora AI Content Translator and how the
                  translation process
                  works</p>
                <StaticImage src={"../assets/images/dora-ai-translator-diagram.png"}></StaticImage>

                <h4>Public Dora Book Translator</h4>
                <p>While traditional AI models have an input content size limit, translating entire books at once is not
                  possible.</p>
                <p>We will develop a tool that's built on top of a special ai model that accepts large text format
                  input.</p>
                <p>Dora AI book translator is an exceptional translator for large text format. It is context aware
                  and picks the translation that fits the context of the text.This tool will enable users to translate books into multiple languages by uploading text-based books
                  to Dora AI.
                </p>

                <h4>Version 1.0 of Dora Twitter Agent </h4>
                <p>We are planning the development of Version 1.0 of the Twitter Agent Dora, aimed at promoting our
                  services through crypto, use case, and blockchain content on Twitter.</p>
                <p>The twitter agent will make regular updates based on the market data and news.</p>
                <p>This agent aims to create traffic, awareness and increase the community of our platform.</p>

                <p>Below you can see a high level absrtaction diagram for the Dora AI Twitter agent:</p>
                <StaticImage src={"../assets/images/dora-twitter-agent.png"}></StaticImage>

                <h4>Dora Smarter Everyday Agent</h4>
                <p>An AI companion that shares motivational quotes and insights from personal development books.
                  This initiative, aims to increase our reach into more spheres of interest while inspiring and
                  empowering users to achieve their goals.
                </p>

                <p>We want to integrate this agent with multiple platforms like Instagram and Facebook. This means
                  adding
                  image generation capabilities, so the Agent can share images with quotes and insights.
                </p>

                <p>Our goal with this project is to expand our reach and increase the community size, and in the end,
                  providing
                  other known brands and accounts with specialized AI + Social Media agents for their own niche.
                  This agent is not meant to generate revenue, but to generate reach and awareness of our platform.
                </p>

                <h4>Chatbot Integrations</h4>
                <p>As part of our future initiatives, we are excited to unveil our plans for chatbot integrations across
                  various platforms. Leveraging AI technology and the Dora token, we aim to create a chatbot agent
                  accessible on multiple platforms:
                  <ul>
                    <li>Whatsapp</li>
                    <li>Facebook Messenger</li>
                    <li>Telegram</li>
                    <li>Slack</li>
                    <li>Shopify</li>
                    <li>Wordpress</li>
                    <li>Wix.com</li>
                  </ul>
                  By integrating chatbots into these platforms, we aim to expand the reach and impact of Dora.ai, bringing AI-powered solutions to a
                  broader audience and driving innovation in the AI space.
                  This will be a revenue-generating project, as for some use-cases we might charge a fee for using the bots.
                  Holders of $Dora token will have discounts on these fees.
                </p>

                <h4>Ongoing Exploration: Investing in Token Adoptability</h4>
                <p>We are actively exploring opportunities to enhance token adoptability through partnerships, use case
                  development, and community engagement. While these initiatives are still in the early stages, we are
                  committed to driving token utility and accessibility in the future.</p>

                <p>The best use cases are the ones needed the most. That's why we will add a page on our website where
                  token holders can add and vote
                  project ideas for the future.</p>
                <p>Based on these ideas we will create different channels for collaborating on existing platforms like:
                  slack or discord to facilitate colaboration
                  and progress sharing. We will incentivise this behaviour with rewards from our Community Rewards pool
                  and to ensure project success we will assign "champions" for the project. The role of a "champion" is
                  not to work the hardest, but to oversee the big picture, to check on progress and to maintain
                  everyone in sync with the project's roadmap.</p>


                <h4>Roadmap Note</h4>
                <p>Based on our experience developing AI services we've learned a lot and we know that what is on our
                  current roadmap is
                  achievable and not something that we are strangers of. Based on the things we've done in the past we
                  are confident we can deliver them iteratively.</p>
                <p>While these projects are currently in the early stages and have not yet been deployed, they
                  represent our commitment to leveraging AI technology and $Dora tokens to empower users and drive
                  innovation in the AI industry.</p>

                <p>
                  Join us on our journey to unlock the full potential of artificial intelligence with the $Dora token.
                  Together, we can shape a future where AI is accessible, inclusive, and transformative for all.
                </p>
              </article>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </Layout>
  )
}

export default PrivacyPolicy;
